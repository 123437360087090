import * as React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import LinksSlider from "./linksSlider"
import FooterLinks from "./footerLinks";
import SocialSite from "./socialSite";

const FooterContact = () => (
  <StaticQuery
  query={graphql`
    {
      allWpPage(filter: {slug: {eq: "global-page"}}) {
        nodes {
          globalPage {
            footerLogo {
              altText
              mediaItemUrl
            }
            socialsHeader {
              name
              url
            }
            contactDetails {
              contactDetail
            }
            hrInquiriesContent
            hrInquiriesTitle
            projectInquiriesContent
            projectInquiriesTitle
            locations {
                location
            }
          }
        }
      }

      wpMenu(slug: {eq: "footer-menu"}) {
        menuItems {
          nodes {
            id
            label
            path
          }
        }
      }
    }
  `}
  render={data => {
    const pageContent = data.allWpPage.nodes[0].globalPage;
    const linkMenu = data.wpMenu.menuItems.nodes;
    return(
        <footer className="footer">
          <div className="footer-top footer-contact">
            <div className="container">
              <h2 className="font-light mb-4 mb-xl-5">Get <em>in</em> touch</h2>

              <div className="row">
                  <div className="col-md-5 col-sm-6 col-lg-4 mb-md-0 mb-4">
                      <h3 className="font-light mb-xl-4 mb-3">{pageContent?.projectInquiriesTitle}</h3>
                      <div className="address-desktop d-lg-block d-none" dangerouslySetInnerHTML={{__html: pageContent?.projectInquiriesContent}} />
                      <div className="d-lg-none" dangerouslySetInnerHTML={{__html: pageContent?.projectInquiriesContent}} />
                  </div>
                  <div className="col-md-5 col-sm-6 col-lg-4 mx-auto">
                      <h3 className="font-light mb-xl-4 mb-3">{pageContent?.hrInquiriesTitle}</h3>
                      <div dangerouslySetInnerHTML={{__html: pageContent?.hrInquiriesContent}} />
                  </div>
              </div>
            </div>
          </div>
          <div className="footer-location mb-0 h2">
              <div className="container d-flex text-nowrap align-items-center justify-content-between">
                {pageContent?.locations && pageContent?.locations.map((loc, index) => (
                    <span key={index} dangerouslySetInnerHTML={{__html: loc.location}} />
                ))}
              </div>
          </div>
          <div className="footer-middle">
            <div className="container">
              <LinksSlider menu={linkMenu} />
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
            <div className="row align-items-center mb-sm-4">
                <div className="col-md-5 mb-md-0 mb-3 col-lg-6">
                  <Link aria-label="footer media" to="/">
                    <img loading="lazy" width="220" height="100" style={{maxWidth: '220px'}} src={pageContent?.footerLogo.mediaItemUrl} alt={pageContent?.footerLogo.altText} className="gatsby-image-wrapper" />
                  </Link>
                </div>
                <div className="col-md-7 col-lg-6">
                  <div className="header-social d-flex align-items-center justify-content-between">
                    <SocialSite socialLinks={pageContent?.socialsHeader} />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <ul className="list-unstyled d-flex align-items-center flex-wrap mb-md-0">
                  <FooterLinks />
                </ul>

                <p className="mb-0">Copyright {new Date().getFullYear()} Alkye Services Pty Ltd. All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
    )
  }}
  ></StaticQuery>
  );

export default FooterContact
