import * as React from "react"
import { graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layoutContact"
import Seo from "../components/seo"
import ContactForm from "../components/contactForm"

const ContactPage = ({data}) => {
  const [random, setRandom] = React.useState(1);
 
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      var min = 1;
      var max = 4;
      var rand =  min + (Math.random() * (max-min));
      // this.setState ({this.state.random : rand})
      setRandom(Math.round(rand))
    }, 1500);
    return () => clearInterval(intervalId);
  }, []);

  const pageContent = data.allWpPage.nodes[0].contactUs;
  const pageSeo = data.allWpPage.nodes[0];

  return(
    <Layout>
      
      <Seo title={pageSeo.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageSeo.seo.metaDesc} focuskw={pageSeo.seo.focuskw} />
      
      <div className="page-intro page-inner">
        <div className="container">
          <ul className="contact-lang list-unstyled text-nowrap flex-wrap justify-content-between d-flex align-items-center">
            {pageContent && pageContent?.bannerLanguage.map((lang, index) => (
              <li key={index} style={{fontFamily: lang.fontFamily, fontWeight: parseInt(lang.fontWeight)}} className={random === parseInt(lang.position) ? 'active' : ''}><span>{lang.language}</span></li>
            ))}
          </ul>
          {/* <ul className="contact-lang list-unstyled text-nowrap flex-wrap justify-content-between d-flex align-items-center">
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 1 ? 'active' : ''}><span>Hello!</span></li>
              <li style={{fontFamily: 'Geeza Pro', fontWeight: 400}} className={random === 4 ? 'active' : ''}><span>مرحبا</span></li>
              <li style={{fontFamily: 'Lucida Grande', fontWeight: 100}} className={random === 3 ? 'active' : ''}><span>שלום</span></li>
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 2 ? 'active' : ''}><span>Bonjour</span></li>
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 3 ? 'active' : ''}><span>Hola</span></li>
              <li style={{fontFamily: 'PingFang SC', fontWeight: 400}} className={random === 1 ? 'active' : ''}><span>Привет</span></li>
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 2 ? 'active' : ''}><span>G’day</span></li>
              <li style={{fontFamily: 'PingFang SC', fontWeight: 100}} className={random === 4 ? 'active' : ''}><span>안녕하세요</span></li>
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 2 ? 'active' : ''}><span>Habari</span></li>
              <li style={{fontFamily: 'PingFang SC', fontWeight: 100}} className={random === 4 ? 'active' : ''}><span>γεια σας</span></li>
              <li style={{fontFamily: 'Devanagari MT', fontWeight: 400}} className={random === 1 ? 'active' : ''}><span>नमस्ते</span></li>
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 3 ? 'active' : ''}><span>Hallo</span></li>
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 1 ? 'active' : ''}><span>Përshëndetje</span></li>
              <li style={{fontFamily: 'PingFang SC', fontWeight: 400}} className={random === 3 ? 'active' : ''}><span>你好</span></li>
              <li style={{fontFamily: 'Geeza Pro', fontWeight: 400}} className={random === 2 ? 'active' : ''}><span>ہیلو</span></li>
              <li style={{fontFamily: 'Cambon', fontWeight: 100}} className={random === 4 ? 'active' : ''}><span>Ciao</span></li>
          </ul> */}
        </div>
      </div>

      <ContactForm data={pageContent} />
    
    </Layout>
  )
}

export default ContactPage

export const query = graphql`{
  allWpPage(filter: {slug: {eq: "contact-us"}}) {
    nodes {
      seo {
        title
        metaDesc
        focuskw
      }
      contactUs {
        fieldGroupName
        subtitle
        thankYouMessage
        title
        bannerLanguage {
          fontFamily
          fontWeight
          language
          position
        }
      }
    }
  }
}`