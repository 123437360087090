import React, { Component } from 'react'
import PhoneInput from 'react-phone-input-2';

export default class contactForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            phone: '',
            phoneError: '',
            success: false,
            stepCount: 1,
            fields: {},
            errors: {},
        };

        this.contactSubmit = this.contactSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }

      handleStep = (count) => {
        this.setState({
            stepCount: count
        });
      }

    //   handleSubmit = () => {
    //     this.setState({
    //         success: true
    //     });
    //   }

      handleRedo = () =>{
        this.setState({
            success: false,
            stepCount: 1,
            phone: '',
            fields:{
                user_name: '',
                email: '',
                organization: '',
                description: ''
            }
        });
      }

      contactSubmit = e => {
        e.preventDefault();

        if(this.validateForm()) {
            this.setState({
                success: true,
                stepCount: 1
            });

            
            
            var formdata = new FormData();
            formdata.append("user_name", this.state.fields.user_name);
            formdata.append("email", this.state.fields.email);
            formdata.append("phone", this.state.phone);
            formdata.append("organization", this.state.fields.organization);
            formdata.append("description", this.state.fields.description);

            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };

            fetch("https://dev.alkye.com/form-contact/", requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }
      }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })

		// console.log(e.target.value)
	}

    validateForm() {
        let phoneError = this.state.phoneError;
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
	
		// First Name error messages
		if (!fields["user_name"]) {
		  formIsValid = false;
		  errors["user_name"] = "*Please enter your First Name.";
          this.setState({
            stepCount: 1
          });
		}
	
		// Email Address error messages
		if (!fields["email"]) {
		  formIsValid = false;
		  errors["email"] = "*Please enter your Email Address";
          this.setState({
            stepCount: 2
          });
		}
	
		if (typeof fields["email"] !== "undefined") {
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter valid Email Address";
            this.setState({
                stepCount: 2
            });
		  }
		}
	
        if (!this.state.phone) {
			formIsValid = false;
			phoneError = "*Please enter your phone number.";
            this.setState({
                stepCount: 3
            });
		}
	
		// Password error messages
		if (!fields["organization"]) {
		  formIsValid = false;
		  errors["organization"] = "*Please enter your organization.";
          this.setState({
            stepCount: 4
          });
		}
		
		// Confirm Password error messages
		if (!fields["description"]) {
		  formIsValid = false;
		  errors["description"] = "*Please enter your description.";
          this.setState({
            stepCount: 5
          });
		}
	

	
	
		this.setState({
            phoneError,
		  errors: errors
		});
		return formIsValid;
	}

      handleKeyDown = (e, count) => {
          if (e.key === 'Enter') {
                // if (this.validateForm()) {
                    this.setState({
                        stepCount: count
                    });
                // }

                // if(count === "success"){
                //     this.contactSubmit();
                // }
        }
      }

  render() {
      const pageContent = this.props.data;

    return (
        <div className='contact-form'>
            <div className='container container-lg'>
                <div className="row mb-lg-5 mb-4">
                    <div className="col-sm-12 col-md-12 col-lg-9  col-xl-11 col-xxl-12 mb-3">
                        <h1 data-aos="fade-up" data-aos-delay="200">{pageContent?.title}</h1>
                        <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.subtitle}</p>
                    </div>
                </div>

                {this.state.success ? (
                    <div className='contact-form-thankyou pt-4 text-center'>
                        <h2 className='mb-4'>{pageContent?.thankYouMessage}</h2>

                        <button aria-label="Re send" onClick={() => this.handleRedo()} className='btn ms-3 btn-control py-lg-2 px-lg-4'>Re-do</button>
                    </div>
                ): (
                    <form onSubmit={this.contactSubmit}>
                        <div className='contact-form-steps'>
                            {this.state.stepCount === 1 && (
                                <div className='contact-form-step'>
                                    <div className='contact-count'>01<sup>/05</sup></div>

                                    <div className='contact-field'>
                                        <label htmlFor='userName'>What's your first and last name?*</label>
                                        <input id="userName" className='form-control' onChange={this.handleChange} value={this.state.fields.user_name} name='user_name' autoFocus={true} onKeyDown={(e) => this.handleKeyDown(e, 2)} placeholder='Type your answer here....' type="text" />
                                    </div>

                                    {/* <div className={`invalid-feedback mt-2 ${!this.state.fields.user_name ? (this.state.errors.user_name ? 'd-block' : null) : null }`}>{this.state.errors.user_name}</div> */}

                                    <div className='contact-field-controls d-flex align-items-center justify-content-between'>
                                        <div className='ms-auto d-flex align-items-center'>
                                            <span className='d-none'>Press Enter 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi mx-md-2 mx-1 bi-arrow-return-left" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                                or</span>
                                            <button aria-label="Press Enter" onClick={() => this.handleStep(2)} className='btn ms-3 btn-control'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.stepCount === 2 && (
                                <div className='contact-form-step'>
                                    <div className='contact-count'>02<sup>/05</sup></div>

                                    <div className='contact-field'>
                                        <label htmlFor='email'>What's your work email?*</label>
                                        <input id="email" className='form-control' onChange={this.handleChange} value={this.state.fields.email} name="email" autoFocus={true} onKeyDown={(e) => this.handleKeyDown(e, 3)} placeholder='Type your answer here....' type="text" />
                                    </div>

                                    {/* <div className={`invalid-feedback mt-2 ${!this.state.fields.email ? (this.state.errors.email ? 'd-block' : null) : null }`}>{this.state.errors.email}</div> */}

                                    <div className='contact-field-controls d-flex align-items-center justify-content-between'>
                                        <button aria-label="Form step 1" onClick={() => this.handleStep(1)} className='btn btn-control'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                            </svg>
                                        </button>
                                        <div className='ms-auto d-flex align-items-center'>
                                            <span className='d-none'>Press Enter 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi mx-md-2 mx-1 bi-arrow-return-left" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                                or</span>
                                            <button aria-label="Press Enter 2" onClick={() => this.handleStep(3)} className='btn ms-3 btn-control'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.stepCount === 3 && (
                                <div className='contact-form-step'>
                                    <div className='contact-count'>03<sup>/05</sup></div>

                                    <div className='contact-field'>
                                        <label htmlFor='phone'>What's your phone number?*</label>
                                        {/* <input id="phone" className='form-control' onChange={this.handleChange} name='phone' autoFocus={true} required={true} onKeyDown={(e) => this.handleKeyDown(e, 4)} placeholder='Type your answer here....' type="text" /> */}
                                        <PhoneInput
                                        inputExtraProps={{
                                            required: true,
                                            autoFocus: true
                                        }}
                                        country={'au'}
                                        placeholder="Type your answer here...."
                                        enableAreaCodeStretch
                                        enableAreaCodes={true}
                                        value={this.state.phone}
                                        onKeyDown={(e) => this.handleKeyDown(e, 4)}
                                        dropdownClass="form-shadow form-radius border-0"
                                        inputClass="form-control"
                                        onChange={(phone) => this.setState({ phone })}
                                    />
                                    </div>

                                    {/* <div
                                    className={`invalid-feedback mt-2 ${
                                        !this.state.phone
                                        ? this.state.phoneError
                                            ? "d-block"
                                            : null
                                        : null
                                    }`}
                                    >
                                    {this.state.phoneError}
                                    </div> */}

                                    <div className='contact-field-controls d-flex align-items-center justify-content-between'>
                                        <button aria-label="Step 2" onClick={() => this.handleStep(2)} className='btn btn-control'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                            </svg>
                                        </button>
                                        <div className='ms-auto d-flex align-items-center'>
                                            <span className='d-none'>Press Enter 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi mx-md-2 mx-1 bi-arrow-return-left" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                                or</span>
                                            <button aria-label="Step 4" onClick={() => this.handleStep(4)} className='btn ms-3 btn-control'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.stepCount === 4 && (
                                <div className='contact-form-step'>
                                    <div className='contact-count'>04<sup>/05</sup></div>

                                    <div className='contact-field'>
                                        <label htmlFor='organization'>What organization do you represent?*</label>
                                        <input id="organization" className='form-control' onChange={this.handleChange} value={this.state.fields.organization} name='organization' autoFocus={true} onKeyDown={(e) => this.handleKeyDown(e, 5)} placeholder='Type your answer here....' type="text" />
                                    </div>

                                    {/* <div className={`invalid-feedback mt-2 ${!this.state.fields.organization ? (this.state.errors.organization ? 'd-block' : null) : null }`}>{this.state.errors.organization}</div> */}

                                    <div className='contact-field-controls d-flex align-items-center justify-content-between'>
                                        <button aria-label="Step 3" onClick={() => this.handleStep(3)} className='btn btn-control'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                            </svg>
                                        </button>
                                        <div className='ms-auto d-flex align-items-center'>
                                            <span className='d-none'>Press Enter 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi mx-md-2 mx-1 bi-arrow-return-left" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                                or</span>
                                            <button aria-label="Step 5" onClick={() => this.handleStep(5)} className='btn ms-3 btn-control'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.stepCount === 5 && (
                                <div className='contact-form-step'>
                                    <div className='contact-count'>05<sup>/05</sup></div>

                                    <div className='contact-field'>
                                        <label htmlFor='description'>What are the goals of this project?*</label>
                                        <textarea id='description' className='form-control' onChange={this.handleChange} value={this.state.fields.description} name='description' autoFocus={true} onKeyDown={(e) => this.handleKeyDown(e, "success")} placeholder='Type your answer here....'></textarea>
                                    </div>

                                    {/* <div className={`invalid-feedback mt-2 ${!this.state.fields.description ? (this.state.errors.description ? 'd-block' : null) : null }`}>{this.state.errors.description}</div> */}

                                    <div className='contact-field-controls d-flex align-items-center justify-content-between'>
                                        <button aria-label="Step 4" onClick={() => this.handleStep(4)} className='btn btn-control'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                            </svg>
                                        </button>
                                        <div className='ms-auto d-flex align-items-center'>
                                            {/* <span>Press Enter 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi mx-md-2 mx-1 bi-arrow-return-left" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                                or</span> */}
                                                {/*  onClick={() => this.handleSubmit()} */}
                                            <button aria-label="Submit" type='submit' className='btn ms-3 d-inline-flex btn-control text-uppercase'>
                                                <span className='d-inline-flex align-items-center' style={{minHeight: '30px'}}>
                                                    submit 
                                                </span>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg> */}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                )}
                
                <div className='pt-4'>
                    <div className={`invalid-feedback mt-2 ${!this.state.fields.user_name ? (this.state.errors.user_name ? 'd-block' : null) : null }`}>Step 01: {this.state.errors.user_name}</div>
                    {/* <div className={`invalid-feedback mt-2 ${!this.state.fields.email ? (this.state.errors.email ? 'd-block' : null) : null }`}>Step 02: {this.state.errors.email}</div> */}
                    <div className={`invalid-feedback mt-2 ${!this.state.fields.email ? (this.state.errors.email ? 'd-block' : null) : null }`}>Step 02: {this.state.errors.email} or valid Email Address.</div>
                    <div className={`invalid-feedback mt-2 ${!this.state.phone ? this.state.phoneError ? "d-block" : null : null}`}>Step 03: {this.state.phoneError}</div>
                    <div className={`invalid-feedback mt-2 ${!this.state.fields.organization ? (this.state.errors.organization ? 'd-block' : null) : null }`}>Step 04: {this.state.errors.organization}</div>                                    
                    <div className={`invalid-feedback mt-2 ${!this.state.fields.description ? (this.state.errors.description ? 'd-block' : null) : null }`}>Step 05: {this.state.errors.description}</div>
                </div>

                {/* {this.state.fields.user_name}, {this.state.fields.email}, {this.state.phone}, {this.state.fields.organization}, {this.state.fields.description} */}

            </div>
        </div>
    )
  }
}
